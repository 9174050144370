import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      phone: '',
      avatar: '',
      nick: '',
      userId: 0,
      mId: 0,
      token: ''
    },
    panoramaInfo: {
      id: 0,
      m_id: '',
      uid: '',
      title: '',
      desc: '',
      img: '',
      // 是否显示LOGO
      isLogo: 1,
      // 自定义logo
      logo: '',
      // 是否开启留言功能 1 关闭 2开启
      isMessage: 2,
      // 是否显示留言内容 1 不显示  2 显示
      isViewMessage: 2,
      // 是否开启陀螺仪功能 1不开启  2开启
      isGyroscope: 2,
      // 是否显示浏览量 1 关闭 2开启
      isViews: 2,
      // 浏览量
      viewsNum: 0,
      // 是否显示项目简介 1关闭  2显示
      isIntroduction: 2,
      // 项目简介内容
      introduction: '',
      // 是否显示点赞 1关闭  2显示
      isAgree: 2,
      // 点赞数量
      agreeNmu: 0,
      // 是否开启分享功能 1 关闭  2开启
      isShare: 2,
      // 是否开启全屏功能 1 关闭  2开启
      isFullScreen: 2,
      // 是否开启VR功能 1 关闭  2开启
      isVr: 2,
      // 是否开启背景音乐 1 关闭 2开启
      isBgMusic: 1,
      // 背景音乐url
      bgMusic: '',
      // 项目沙盘图片
      sand_img: '',
      // 微信分享相关
      wxImg: '',
      wxTitle: '',
      wxDesc: ''
    },
    // 场景列表
    sceneList: []
  },
  mutations: {
    updatePanoramaItem (state, setItem) {
      state.panoramaInfo[setItem.name] = setItem.val
    },
    // 更新VR项目信息
    updatePanoramaAll (state, panoramaInfo) {
      state.panoramaInfo.id = panoramaInfo.id
      state.panoramaInfo.m_id = panoramaInfo.m_id
      state.panoramaInfo.uid = panoramaInfo.uid
      state.panoramaInfo.title = panoramaInfo.title
      state.panoramaInfo.desc = panoramaInfo.desc
      state.panoramaInfo.img = panoramaInfo.img
      state.panoramaInfo.isLogo = panoramaInfo.isLogo
      state.panoramaInfo.logo = panoramaInfo.logo
      state.panoramaInfo.isMessage = panoramaInfo.isMessage
      state.panoramaInfo.isViewMessage = panoramaInfo.isViewMessage
      state.panoramaInfo.isGyroscope = panoramaInfo.isGyroscope
      state.panoramaInfo.isViews = panoramaInfo.isViews
      state.panoramaInfo.viewsNum = panoramaInfo.viewsNum
      state.panoramaInfo.isIntroduction = panoramaInfo.isIntroduction
      state.panoramaInfo.introduction = panoramaInfo.introduction
      state.panoramaInfo.isAgree = panoramaInfo.isAgree
      state.panoramaInfo.agreeNmu = panoramaInfo.agreeNmu
      state.panoramaInfo.isShare = panoramaInfo.isShare
      state.panoramaInfo.isFullScreen = panoramaInfo.isFullScreen
      state.panoramaInfo.isVr = panoramaInfo.isVr
      state.panoramaInfo.isBgMusic = panoramaInfo.isBgMusic
      state.panoramaInfo.bgMusic = panoramaInfo.bgMusic
      state.panoramaInfo.sand_img = panoramaInfo.sand_img
      state.panoramaInfo.wxImg = panoramaInfo.wxImg
      state.panoramaInfo.wxTitle = panoramaInfo.wxTitle
      state.panoramaInfo.wxDesc = panoramaInfo.wxDesc
    },
    // 更新场景列表信息
    updateSceneList (state, sceneList) {
      state.sceneList = sceneList
    },
    // 更新镜头列表信息
    updateCameraList (state, cameraList) {
      state.cameraList = cameraList
    },
    // 更新热点列表信息
    updateHotsList (state, hotsList) {
      state.hotsList = hotsList
    },
    // 更新用户信息
    updateUser (state, userinfo) {
      state.userInfo.phone = userinfo.phone
      state.userInfo.avatar = userinfo.avatar
      state.userInfo.nick = userinfo.nick
      state.userInfo.userId = userinfo.userId
      state.userInfo.mId = userinfo.mId
    },
    // 清除用户信息
    clearUser (state) {
      state.userInfo.phone = ''
      state.userInfo.avatar = ''
      state.userInfo.nick = ''
      state.userInfo.userId = 0
      state.userInfo.mId = 0
    },
    // 更新token
    updateToken (state, token) {
      state.userInfo.token = token
    },
    // 清除token
    clearToken (state) {
      state.userInfo.token = ''
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    reducer (val) {
      return {
        // 只储存state中的assessmentData
        userInfo: val.userInfo
      }
    }
  })]
})
