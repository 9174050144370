<template>
  <div class="login-page">
    <div class="login-panel">
      <div class="left"></div>
      <div class="right">
        <div class="title">管理后台</div>
        <el-form :model="loginForm" :rules="loginRules" ref="loginRef" class="login-form">
          <el-form-item prop="phone">
            <el-input v-model="loginForm.phone">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="loginForm.password" show-password>
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item>
          <el-form-item style="text-align: center;margin-top: 40px">
            <el-button type="primary" @click="submitForm('loginRef')" :loading="statusLoading">立即登录</el-button>
          </el-form-item>
        </el-form>
        <div class="more-info">
          <div class="item" @click="toRegister">
            注册账号
          </div>
          <div class="item" @click="toReset">
            忘记密码
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    const validatorPhone = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('手机号不能为空'))
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error('手机号格式错误'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        phone: '',
        password: ''
      },
      statusLoading: false,
      loginRules: {
        phone: [
          { required: true, message: '请输入登录手机账号', trigger: 'blur' },
          { validator: validatorPhone, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.statusLoading = true
          try {
            const { data: res } = await this.$http.post('/login', this.loginForm)
            if (res.status === 200) {
              this.$message.success(res.msg)
              // 存储用户数据到vuex
              this.$store.commit('updateUser', res.data)
              this.statusLoading = false
              await this.$router.push('/admin')
            } else {
              this.$message.error(res.msg)
              this.statusLoading = false
            }
          } catch (error) {
            this.statusLoading = false
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    toRegister () {
      this.$router.push('/register')
    },
    toReset () {
      this.$router.push('/reset-password')
    }
  }
}
</script>

<style scoped>
  .login-page{
    background-image: url('../assets/img/background.jpg');
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-panel{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-panel .left{
    width: 200px;
    height: 400px;
    background-image: url('../assets/img/left-bg.jpg');
    background-size: cover;
    border-radius: 6px 0 0 6px;
  }
  .login-panel .right{
    width: 400px;
    height: 400px;
    background-color: rgba(255,255,255,0.8);
    padding: 40px 20px;
    border-radius: 0 6px 6px 0;
  }
  .right .title{
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    opacity: 0.8;
    padding: 20px 0;
  }
  .more-info{
    font-size: 12px;
    text-align: center;
  }
  .more-info .item{
    cursor: pointer;
    display: inline-flex;
    margin: 10px;
  }
  .more-info .item:hover{
    color: #409EFF;
  }
</style>
