<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html,body{
  padding: 0;
  margin: 0;
}
*{
  box-sizing: border-box;
}
.el-form-item{
  text-align: left;
}
.page-content{
  margin-top: 30px;
}
</style>
