import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import VueClipboard from 'vue-clipboard2'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import VueQr from 'vue-qr'

import * as THREE from 'three'

import Moment from 'moment'
// 手动百分比插件
import KnobControl from 'vue-knob-control'

// 导入webscoket
import global from '@/assets/js/global'

// 打印插件
import Print from 'vue-print-nb'

if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'https://huihaometa.com/admin/'
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = 'https://huihaometa.com/admin/'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://huihaometa.com/admin/'
}
axios.defaults.timeout = 100000
axios.defaults.headers = {
  'Content-Type': 'application/json'
}

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  console.log(config)
  // 在发送请求之前做些什么
  // 判断本地是否存在token的session
  if (sessionStorage.getItem('token')) {
    config.headers.Authorization = sessionStorage.getItem('token')
  }
  // 判断是否存在username
  if (store.state.userInfo.mId && store.state.userInfo.phone && config.url !== '/login') {
    config.headers.mId = store.state.userInfo.mId
    config.headers.phone = store.state.userInfo.phone
  }
  // 开启进度条
  NProgress.start()
  return config
}, function (error) {
  // 关闭进度条
  NProgress.done()
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  // 判断返回头中是否存在token,如果存在则替换掉本地的token
  if (typeof response.headers.authorization !== 'undefined') {
    window.sessionStorage.setItem('token', response.headers.authorization)
    store.commit('updateToken', response.headers.authorization)
  }
  // 关闭进度条
  NProgress.done()
  return response
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  // token过期
  // 关闭进度条
  NProgress.done()
  return Promise.reject(error)
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // if (to.path === '/login' || to.path === '/register' || to.path === '/' || to.path === '/find-password') {
  //   // 清除缓存
  //   store.commit('clearUser')
  //   next()
  // } else {
  //   if (!store.state.userInfo.phone || !store.state.userInfo.token) {
  //     ElementUI.Message.error('您还没有登录，请先登录')
  //     next('/login')
  //   }
  //   next()
  // }
  next()
})

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueAwesomeSwiper)
Vue.use(VueQr)
Vue.use(KnobControl)
Vue.use(Print)
const TcPlayer = window.TcPlayer
Vue.prototype.TcPlayer = TcPlayer
Vue.prototype.THREE = THREE

Vue.prototype.$http = axios

Vue.prototype.$moment = Moment
Vue.prototype.global = global
const bus = new Vue()
Vue.prototype.$bus = bus

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
