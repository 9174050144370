import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', redirect: '/login' },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: () => import('../views/Register.vue') },
  {
    path: '/admin',
    name: 'Dashboard',
    redirect: '/admin/index',
    component: () => import('../views/Admin.vue'),
    children: [
      { path: '/admin/index', component: () => import('../components/dashboard/Dashboard.vue') },
      { path: '/admin/setup/site', component: () => import('../components/setup/Site.vue') },
      { path: '/admin/setup/wx', component: () => import('../components/setup/Wx.vue') },
      { path: '/admin/setup/pay', component: () => import('../components/setup/Pay.vue') },
      { path: '/admin/setup/mail', component: () => import('../components/setup/Mail.vue') },
      { path: '/admin/setup/sms', component: () => import('../components/setup/Sms.vue') },
      { path: '/admin/setup/trtc', component: () => import('../components/setup/Trtc.vue') },
      { path: '/admin/setup/webscoket', component: () => import('../components/setup/Webscoket.vue') },
      { path: '/admin/account/agent', component: () => import('../components/account/Agent.vue') },
      { path: '/admin/account/role', component: () => import('../components/account/Role.vue') },
      { path: '/admin/account/auth', component: () => import('../components/account/Auth.vue') },
      { path: '/admin/apps/index', component: () => import('../components/apps/Index.vue') },
      { path: '/admin/apps/panorama/index', component: () => import('../components/apps/panorama/ProjectList') },
      // 会议壳
      { path: '/admin/apps/shell/index', component: () => import('../components/apps/shell/Index.vue') },
      { path: '/admin/apps/shell/bg', component: () => import('../components/apps/shell/backgroundImg/Index.vue') },
      { path: '/admin/apps/shell/icon', component: () => import('../components/apps/shell/icon/Index.vue') },
      { path: '/admin/apps/shell/member', component: () => import('../components/apps/shell/member/Index.vue') },
      { path: '/admin/apps/shell/record', component: () => import('../components/apps/shell/record/Index.vue') },
      { path: '/admin/apps/shell/meeting-list', component: () => import('../components/apps/shell/MeetingList.vue') },
      { path: '/admin/apps/shell/add', component: () => import('../components/apps/shell/meeting/Add.vue') },
      { path: '/admin/apps/shell/edit/:mId', component: () => import('../components/apps/shell/meeting/Edit.vue') },
      { path: '/admin/apps/shell/content/index/:mId', component: () => import('../components/apps/shell/content/Index.vue') },
      { path: '/admin/apps/shell/content/nav/:mId', component: () => import('../components/apps/shell/content/nav/Index.vue') },
      { path: '/admin/apps/shell/content/contacts/:mId', component: () => import('../components/apps/shell/content/contacts/Index.vue') },
      { path: '/admin/apps/shell/content/contacts/:mId/add', component: () => import('../components/apps/shell/content/contacts/Add.vue') },
      { path: '/admin/apps/shell/content/contacts/:mId/edit/:id', component: () => import('../components/apps/shell/content/contacts/Edit.vue') },
      { path: '/admin/apps/shell/content/traffic/:mId', component: () => import('../components/apps/shell/content/traffic/Index.vue') },
      { path: '/admin/apps/shell/content/traffic/:mId/add', component: () => import('../components/apps/shell/content/traffic/Add.vue') },
      { path: '/admin/apps/shell/content/traffic/:mId/edit/:id', component: () => import('../components/apps/shell/content/traffic/Edit.vue') },
      { path: '/admin/apps/shell/content/guest/:mId', component: () => import('../components/apps/shell/content/guest/Index.vue') },
      { path: '/admin/apps/shell/content/guest/:mId/add', component: () => import('../components/apps/shell/content/guest/Add.vue') },
      { path: '/admin/apps/shell/content/guest/:mId/edit/:id', component: () => import('../components/apps/shell/content/guest/Edit.vue') },
      { path: '/admin/apps/shell/content/article/:mId', component: () => import('../components/apps/shell/content/article/Index.vue') },
      { path: '/admin/apps/shell/content/article-message/:mId', component: () => import('../components/apps/shell/content/article-message/Index.vue') },
      { path: '/admin/apps/shell/content/article/:mId/add', component: () => import('../components/apps/shell/content/article/Add.vue') },
      { path: '/admin/apps/shell/content/article/:mId/edit/:id', component: () => import('../components/apps/shell/content/article/Edit.vue') },
      { path: '/admin/apps/shell/content/schedule/:mId', component: () => import('../components/apps/shell/content/schedule/Index.vue') },
      { path: '/admin/apps/shell/content/schedule/:mId/add', component: () => import('../components/apps/shell/content/schedule/Add.vue') },
      { path: '/admin/apps/shell/content/schedule/:mId/edit/:id', component: () => import('../components/apps/shell/content/schedule/Edit.vue') },
      { path: '/admin/apps/shell/content/live/:mId', component: () => import('../components/apps/shell/content/live/Index.vue') },
      { path: '/admin/apps/shell/content/live/:mId/add', component: () => import('../components/apps/shell/content/live/Add.vue') },
      { path: '/admin/apps/shell/content/live/:mId/edit/:id', component: () => import('../components/apps/shell/content/live/Edit.vue') },
      { path: '/admin/apps/shell/content/video/:mId', component: () => import('../components/apps/shell/content/video/Index.vue') },
      { path: '/admin/apps/shell/content/video/:mId/add', component: () => import('../components/apps/shell/content/video/Add.vue') },
      { path: '/admin/apps/shell/content/video/:mId/edit/:id', component: () => import('../components/apps/shell/content/video/Edit.vue') },
      { path: '/admin/apps/shell/content/photos/:mId', component: () => import('../components/apps/shell/content/photos/Index.vue') },
      { path: '/admin/apps/shell/content/users/:mId', component: () => import('../components/apps/shell/content/users/Index.vue') },
      { path: '/admin/apps/shell/content/clockin/:mId', component: () => import('../components/apps/shell/content/clockin/Index.vue') },
      { path: '/admin/apps/shell/content/clockin/:mId/add', component: () => import('../components/apps/shell/content/clockin/Add.vue') },
      { path: '/admin/apps/shell/content/clockin/:mId/edit/:id', component: () => import('../components/apps/shell/content/clockin/Edit.vue') },
      { path: '/admin/apps/shell/content/clockin/:mId/clockinId/:clockinid/clockinName/:clockinname', component: () => import('../components/apps/shell/content/clockin/ClockinList.vue') },
      { path: '/admin/apps/shell/content/download/:mId', component: () => import('../components/apps/shell/content/download/Index.vue') },
      { path: '/admin/apps/shell/content/download/:mId/add', component: () => import('../components/apps/shell/content/download/Add.vue') },
      { path: '/admin/apps/shell/content/download/:mId/edit/:id', component: () => import('../components/apps/shell/content/download/Edit.vue') },
      { path: '/admin/apps/shell/content/certificate/:mId', component: () => import('../components/apps/shell/content/certificate/Index.vue') },
      // PC网站
      { path: '/admin/apps/pc/index', component: () => import('../components/apps/pc/Index.vue') },
      { path: '/admin/apps/pc/base-set', component: () => import('../components/apps/pc/BaseSet/BaseSet.vue') },
      { path: '/admin/apps/pc/banner', component: () => import('../components/apps/pc/Banner/Index.vue') },
      { path: '/admin/apps/pc/banner/add', component: () => import('../components/apps/pc/Banner/Add.vue') },
      { path: '/admin/apps/pc/banner/edit/:id', component: () => import('../components/apps/pc/Banner/Edit.vue') },
      { path: '/admin/apps/pc/product', component: () => import('../components/apps/pc/Product/Index.vue') },
      { path: '/admin/apps/pc/product/add', component: () => import('../components/apps/pc/Product/Add.vue') },
      { path: '/admin/apps/pc/product/edit/:id', component: () => import('../components/apps/pc/Product/Edit.vue') },
      { path: '/admin/apps/pc/case', component: () => import('../components/apps/pc/Case/Index.vue') },
      { path: '/admin/apps/pc/case/add', component: () => import('../components/apps/pc/Case/Add.vue') },
      { path: '/admin/apps/pc/case/edit/:id', component: () => import('../components/apps/pc/Case/Edit.vue') },
      { path: '/admin/apps/pc/news', component: () => import('../components/apps/pc/News/Index.vue') },
      { path: '/admin/apps/pc/news/add', component: () => import('../components/apps/pc/News/Add.vue') },
      { path: '/admin/apps/pc/news/edit/:id', component: () => import('../components/apps/pc/News/Edit.vue') },
      { path: '/admin/apps/pc/download', component: () => import('../components/apps/pc/Download/Index.vue') },
      { path: '/admin/apps/pc/download/add', component: () => import('../components/apps/pc/Download/Add.vue') },
      { path: '/admin/apps/pc/download/edit/:id', component: () => import('../components/apps/pc/Download/Edit.vue') },
      { path: '/admin/apps/pc/faq', component: () => import('../components/apps/pc/Faq/Index.vue') },
      { path: '/admin/apps/pc/faq/add', component: () => import('../components/apps/pc/Faq/Add.vue') },
      { path: '/admin/apps/pc/faq/edit/:id', component: () => import('../components/apps/pc/Faq/Edit.vue') },
      { path: '/admin/apps/pc/price', component: () => import('../components/apps/pc/Price/Index.vue') },
      { path: '/admin/apps/pc/price/add', component: () => import('../components/apps/pc/Price/Add.vue') },
      { path: '/admin/apps/pc/price/edit/:id', component: () => import('../components/apps/pc/Price/Edit.vue') },
      { path: '/admin/apps/pc/message', component: () => import('../components/apps/pc/Message/Index.vue') },
      { path: '/admin/apps/pc/service', component: () => import('../components/apps/pc/Service/Index.vue') },
      { path: '/admin/apps/pc/service/add', component: () => import('../components/apps/pc/Service/Add.vue') },
      { path: '/admin/apps/pc/service/edit/:id', component: () => import('../components/apps/pc/Service/Edit.vue') },
      { path: '/admin/apps/pc/customer', component: () => import('../components/apps/pc/Customer/Index.vue') },
      { path: '/admin/apps/pc/customer/add', component: () => import('../components/apps/pc/Customer/Add.vue') },
      { path: '/admin/apps/pc/customer/edit/:id', component: () => import('../components/apps/pc/Customer/Edit.vue') },
      { path: '/admin/apps/pc/links', component: () => import('../components/apps/pc/Links/Index.vue') },
      { path: '/admin/apps/pc/links/add', component: () => import('../components/apps/pc/Links/Add.vue') },
      { path: '/admin/apps/pc/links/edit/:id', component: () => import('../components/apps/pc/Links/Edit.vue') },
      { path: '/admin/apps/pc/fw', component: () => import('../components/apps/pc/Fw/Index.vue') },
      { path: '/admin/apps/pc/fw/add', component: () => import('../components/apps/pc/Fw/Add.vue') },
      { path: '/admin/apps/pc/fw/edit/:id', component: () => import('../components/apps/pc/Fw/Edit.vue') },
      { path: '/admin/apps/pc/web-data', component: () => import('../components/apps/pc/webData/Index.vue') },
      // 网上展览
      { path: '/admin/apps/exhibition/index', component: () => import('../components/apps/exhibition/Index.vue') },
      { path: '/admin/apps/exhibition/list', component: () => import('../components/apps/exhibition/List.vue') },
      { path: '/admin/apps/exhibition/add', component: () => import('../components/apps/exhibition/AddItem.vue') },
      { path: '/admin/apps/exhibition/edit/:m_id', component: () => import('../components/apps/exhibition/EditItem.vue') },
      { path: '/admin/apps/exhibition/content/:m_id', component: () => import('../components/apps/exhibition/content/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/banner', component: () => import('../components/apps/exhibition/content/banner/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/banner/add', component: () => import('../components/apps/exhibition/content/banner/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/banner/edit/:item_mid', component: () => import('../components/apps/exhibition/content/banner/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/nav', component: () => import('../components/apps/exhibition/content/nav/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/nav/add', component: () => import('../components/apps/exhibition/content/nav/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/nav/edit/:item_mid', component: () => import('../components/apps/exhibition/content/nav/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/schedule', component: () => import('../components/apps/exhibition/content/schedule/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/schedule/add', component: () => import('../components/apps/exhibition/content/schedule/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/schedule/edit/:item_mid', component: () => import('../components/apps/exhibition/content/schedule/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/exhibitionhall', component: () => import('../components/apps/exhibition/content/exhibitionhall/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/exhibitionhall/add', component: () => import('../components/apps/exhibition/content/exhibitionhall/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/exhibitionhall/edit/:item_mid', component: () => import('../components/apps/exhibition/content/exhibitionhall/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/pavilion', component: () => import('../components/apps/exhibition/content/pavilion/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/pavilion/add', component: () => import('../components/apps/exhibition/content/pavilion/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/pavilion/edit/:item_mid', component: () => import('../components/apps/exhibition/content/pavilion/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/exhibitor', component: () => import('../components/apps/exhibition/content/exhibitor/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/exhibitor/add', component: () => import('../components/apps/exhibition/content/exhibitor/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/exhibitor/edit/:item_mid', component: () => import('../components/apps/exhibition/content/exhibitor/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/product', component: () => import('../components/apps/exhibition/content/product/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/product/add', component: () => import('../components/apps/exhibition/content/product/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/product/edit/:item_mid', component: () => import('../components/apps/exhibition/content/product/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/meeting', component: () => import('../components/apps/exhibition/content/meeting/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/meeting/add', component: () => import('../components/apps/exhibition/content/meeting/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/meeting/edit/:item_mid', component: () => import('../components/apps/exhibition/content/meeting/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/info', component: () => import('../components/apps/exhibition/content/info/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/info/add', component: () => import('../components/apps/exhibition/content/info/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/info/edit/:item_mid', component: () => import('../components/apps/exhibition/content/info/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/message', component: () => import('../components/apps/exhibition/content/message/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/cards', component: () => import('../components/apps/exhibition/content/cards/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/negotiate', component: () => import('../components/apps/exhibition/content/negotiate/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/negotiate/add', component: () => import('../components/apps/exhibition/content/negotiate/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/negotiate/edit/:item_mid', component: () => import('../components/apps/exhibition/content/negotiate/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/inquiry', component: () => import('../components/apps/exhibition/content/inquiry/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/inquiry/add', component: () => import('../components/apps/exhibition/content/inquiry/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/inquiry/edit/:item_mid', component: () => import('../components/apps/exhibition/content/inquiry/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/ticket', component: () => import('../components/apps/exhibition/content/ticket/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/ticket/add', component: () => import('../components/apps/exhibition/content/ticket/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/ticket/edit/:item_mid', component: () => import('../components/apps/exhibition/content/ticket/Edit.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/files', component: () => import('../components/apps/exhibition/content/files/Index.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/files/add', component: () => import('../components/apps/exhibition/content/files/Add.vue') },
      { path: '/admin/apps/exhibition/content/:m_id/files/edit/:item_mid', component: () => import('../components/apps/exhibition/content/files/Edit.vue') }
    ]
  },
  {
    path: '/panorama/:project_id',
    name: 'PanoramaItem',
    redirect: '/panorama/:project_id/base-set',
    component: () => import('../components/apps/panorama/projectItem/Index.vue'),
    children: [
      { path: '/panorama/:project_id/test', name: 'Test', component: () => import('../components/apps/panorama/projectItem/three/Test.vue') },
      { path: '/panorama/:project_id/base-set', name: 'PanoramaBaseSet', component: () => import('../components/apps/panorama/projectItem/BaseSet.vue') },
      { path: '/panorama/:project_id/vision', name: 'PanoramaVision', component: () => import('../components/apps/panorama/projectItem/Vision.vue') },
      { path: '/panorama/:project_id/hots', name: 'PanoramaHots', component: () => import('../components/apps/panorama/projectItem/Hots.vue') },
      { path: '/panorama/:project_id/sand', name: 'PanoramaSand', component: () => import('../components/apps/panorama/projectItem/Sand.vue') }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
